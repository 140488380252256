import React from 'react'
import styled from "@emotion/styled"
import { ArrowElbowRightUp } from "phosphor-react"

const Buttonwrap = styled.div`
a {
    color: rgba(0,0,0,0.75);
    transition: all 0.3s ease;
    text-transform: uppercase;
    font-size: .875rem;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 13px 15px;
    border-radius: 13px;
    background: rgba(0,0,0,0.05);
    &:hover {
      color: #fff;
      background: rgba(0,0,0,0.33);
    }
    svg {
        vertical-align: text-bottom;
    }
}
`

const Url = ({value}) => {
    return (
        <Buttonwrap><a href={value.url} target="_blank" rel="noreferrer">{value.buttonTitle} <ArrowElbowRightUp size={18} /></a></Buttonwrap>
    )
}

export default Url