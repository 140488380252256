import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import "@fontsource/barlow-semi-condensed/500.css"

import Mainnav from "./mainnav"
import Logo from "./logo"
import { X } from "phosphor-react"

const Mainwrap = styled.main`
  padding: 10vh 10vw;
  box-sizing: border-box;
  min-height: 100vh;
  background-image: url(${props => props.bg});
  background-attachment: fixed;
  background-size: cover;
  background-position-x: center;
  width: 100vw;
    > div {
      background-color: rgba(255,255,255,0.95);
      padding: 10vw;
      @media (max-width: 599px) {
        padding: 8vw;
      }
    }
    @media (max-width: 599px) {
      width: 92vw;
      padding: 12.5vh vw;
      width: inherit;
    }
      h1 {
        font-size: 10rem;
        transform: translate(-12px,0);
        padding-bottom: 5vh;
        line-height: .95em;
        background: linear-gradient(to right, #000 20%, #FF0 40%, #FF0 60%, #000 80%);
        background-size: 200% auto;
        color: #000;
        overflow-wrap: break-word;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: shine 10s linear infinite;
        @media (max-width: 599px) {
          font-size: 6rem;
          transform: translate(-5px,0);
        }
    }
    h3, h4 {
      font-family: "Courier";
      font-size: 1.25rem;
      padding: 1vh 10vw 1.5vh 0;
      width: 75%;
      box-sizing: border-box;
      text-transform: uppercase;
      line-height: 1.25em;
      @media (max-width: 599px) {
        width: 100%;
        padding-right: 0;
      }
    }
    h3 {
      margin-bottom: 1vh;
      padding-top: 5vh;
      font-weight: bold;
    }
    h4 {
      @media (max-width: 599px) {
        font-size: 1.1rem;
      }
    }
    p, ul {
        width: 100%;
        box-sizing: border-box;
        padding-left: 25%;
        font-size: 1.1rem;
        line-height: 1.75em;
        margin-bottom: 1.25em;
        box-sizing: border-box;
        padding: 0;
        @media (max-width: 599px) {
          width: 100%;
          padding-right: 0;
        }
        .gatsby-image-wrapper {
          margin: 0;
        }
        h4 {
          margin-bottom: 0;
          margin-top: 2vh;
          padding: 0;
          font-size: 1rem;
          width: 100%;
          font-family: "Courier";
          box-sizing: border-box;
          text-transform: uppercase;
          line-height: 1.25em;
        }
          a {
          color: #000;
          border-bottom: 1px solid rgba(0,0,0,0.25);
          transition: all 0.3s ease;
          text-transform: uppercase;
          font-size: .875rem;
          letter-spacing: 1px;
          font-weight: 600;
          &:hover {
            color: rgb(255,0,0);
          }
        }
    }
    li {
        list-style: none;
    }
    ul li p {
      margin-bottom: 0;
    }
    h6, strong {
        font-weight: 800;
        border-bottom: 1px solid rgba(0,0,0,0.25);
        color: #000;
    }
    strong a {
      border-bottom: 0;
    }
    h6 {
      font-size: 1rem;
      line-height: 1.75em;
      border: 0;
    }
    hr {
      margin: 1.25vh 0 5vh 0;
      border-top: 1px solid rgba(0,0,0,0.5);
      border-bottom: 0;
      width: 100%;
    }
    em {
        font-style: italic;
    }
    .gatsby-image-wrapper {
        margin: 0 auto 5vh auto;
        width: 100%;
    }
    figcaption {
      font-family: "Courier";
      font-size: .875rem;
      letter-spacing: 1px;
      margin-bottom: 5vh;
      text-align: center;
      span {
        padding: 1vh;
      }
    }
    @keyframes shine {
        to {
          background-position: 200% center;
        }
    }
    .button {
        padding: 1.25vw 2vw;
        margin: 1vw auto 0 auto;
        display: block;
        width: 60%;
        text-align: center;
        background-image: -webkit-repeating-linear-gradient(
          135deg
          ,rgb(217,217,217),rgb(217,217,217) 30px,rgb(227,227,227) 30px,rgb(227,227,227) 60px);
        background-image: repeating-linear-gradient(
          135deg
          ,rgb(217,217,217),rgb(217,217,217) 30px,rgb(227,227,227) 30px,rgb(227,227,227) 60px);
        border-radius: 8px;
        border-bottom: none;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-weight: 600;
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: rgba(0,0,0,0.4);
        transition: all 0.3s ease;
        &:hover {
          color: rgba(0,0,0,0.6);
            background-image: -webkit-repeating-linear-gradient(
              135deg
              ,rgb(210,210,210),rgb(210,210,210) 30px,rgb(220,220,220) 30px,rgb(220,220,220) 60px);
            background-image: repeating-linear-gradient(
              135deg
              ,rgb(210,210,210),rgb(210,210,210) 30px,rgb(220,220,220) 30px,rgb(220,220,220)60px);
                box-shadow: 0 0 10px rgba(36,168,224,0.8);
        }
        @media (max-width: 599px) {
          width: 90%;
        }
    }
`
const position = css`
    position: fixed;
    z-index: 10;
    top: 10vh;
    right: 11vw;
    color: rgba(0,0,0,0.4);
    transition: all 0.3s ease;
    &:hover {
      color: rgba(0,0,0,1);
    }
    @media (max-width: 599px) {
      top: 8px;
      right: 8px;
    }
`

const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        bgImage {
          asset {
            url
          }
        }
      }
    }
    `)

    const bgimg = data.sanitySiteSettings.bgImage.asset.url

    return (
    <>
    <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <Link to={closeTo} css={position}>
              <X size={48} weight="light" />
            </Link>
          ) : (
            <>
            <Logo />
            <Mainnav />
            </>
          )}
          <Mainwrap bg={bgimg}>
          <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        duration: 0.5,
                        delay: 0.3
                      }}
            >
            {children}
            </motion.div>
          </Mainwrap>
        </div>
      )}
      
    </ModalRoutingContext.Consumer>
    </>
    )
}

export default Layout